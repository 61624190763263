<template>
	<v-card height="160" flat class="small-border-r">
		<l-map
			v-if="latLng"
			:zoom="zoom"
			:center="latLng"
			:options="mapOptions"
			class="fill-height"
		>
			<l-tile-layer :url="url"/>
			<l-marker :lat-lng="latLng" />
		</l-map>
	</v-card>
</template>

<script>
import {LMap, LMarker, LTileLayer} from "vue2-leaflet";

export default {
	name: "LocationPreview",
	props: {
		latLng: {required: true, type: Object},
	},
	components: {
		LMap,
		LTileLayer,
		LMarker
	},
	data() {
		return {
			zoom: 13,
			url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			mapOptions: {
				zoomSnap: 1
			},
		};
	},
};
</script>
<style lang="scss">
.vue2leaflet-map {
	.leaflet-top, .leaflet-bottom, .leaflet-marker-pane, .leaflet-pane {
		z-index: 1;
	}
}
.small-border-r {
	border-radius: 4px;
}
</style>
